import React, { useState } from 'react';
import Logo from '../assets/Logos.png';
import Logo2 from '../assets/main logo.png';
import {CgMenu, CgClose} from 'react-icons/cg'
import { HashLink as Link } from 'react-router-hash-link';
import { motion } from 'framer-motion';

const Header = () => {

  const [toggleMenu, setToggleMenu] = useState(false);


  return (
    <div className='flex lg:py-8 lg:px-16 py-6 px-4 w-screen'>
        <div className='flex justify-between w-full p-2 border border-gray-400/90 rounded-full'>
            <div className='flex lg:basis-[10%]'>
                <img src={Logo} alt='' className='h-12 lg:w-[3.5rem] w-12'></img>
            </div>
            <div className='lg:flex lg:flex-row hidden basis-[80%] justify-center items-center gap-16'>
                <Link to='#home' smooth><span className='text-base font-semibold cursor-pointer rounded-xl px-2 py-1 hover:bg-gray-400/25'>Home</span></Link>
                <Link to='#about' smooth><span className='text-base font-semibold cursor-pointer rounded-xl px-2 py-1 hover:bg-gray-400/25'>About Us</span></Link>
                <Link to='#culture' smooth><span className='text-base font-semibold cursor-pointer rounded-xl px-2 py-1 hover:bg-gray-400/25'>Culture</span></Link>
                <Link to='#operation' smooth><span className='text-base font-semibold cursor-pointer rounded-xl px-2 py-1 hover:bg-gray-400/25'>Operation</span></Link>
                <Link to='#business' smooth><span className='text-base font-semibold cursor-pointer rounded-xl px-2 py-1 hover:bg-gray-400/25'>Business</span></Link>
            </div>
            <div className='flex lg:basis-[10%] lg:hidden'>
                <span className=''><CgMenu  className='h-12 w-12 text-[#F05C40]/80' onClick = {() => {setToggleMenu(true)}}/></span>
            </div>
            <div className='lg:basis-[10%] hidden lg:flex'>
            </div>
            
        </div>
        {
              toggleMenu && (
                <motion.div className='flex flex-col text-start z-50 top-0 px-4 pt-4 fixed w-screen lg:hidden h-[37rem] rounded-l bg-white' initial={{ x:'100vw'}}
                animate={{ x: 0}} transition={{type: 'spring', duration: 2, bounce: 0.3}}>
                  <CgClose size={50} className='mb-2 text-[#F05C40]' onClick = {() => {setToggleMenu(false)}}/>
                  <span className='flex items-center justify-center mb-9'><img src={Logo2} alt='' className='h-24 w-[8rem]'></img></span>
                  <span className='text-[#F05C40] text-2xl font-semibold mb-6 pl-3'>Home</span>
                  <Link to='#about' className='mb-6 pl-3'><span onClick = {() => {setToggleMenu(false)}} className='text-[#000000]/70 text-2xl font-semibold mb-6'>About us</span></Link>
                  <Link to='#culture' className='mb-6 pl-3'><span onClick = {() => {setToggleMenu(false)}} className='text-[#000000]/70 text-2xl font-semibold mb-6'>Culture</span></Link>
                  <Link to='#operation' className='mb-6 pl-3'><span onClick = {() => {setToggleMenu(false)}} className='text-[#000000]/70 text-2xl font-semibold mb-6'>Operation</span></Link>
                  <Link to='#business' className='mb-6 pl-3'><span onClick = {() => {setToggleMenu(false)}} className='text-[#000000]/70 text-2xl font-semibold mb-6'>Business</span></Link>
                  
                  
                </motion.div>
              )
            }
    </div>
  )
}

export default Header;