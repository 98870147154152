import React from 'react';
import {MdKeyboardDoubleArrowRight} from 'react-icons/md';
import ReactPlayer from 'react-player';
// import VideoPlayer from 'react-video-js-player';
// import Crude from '../assets/video/crudeoil.mp4';
import post from '../assets/video/crude.png'

const url = 'https://drive.google.com/uc?export=download&id=1Ut8R-u4s8ezIXFeW2qgTTSt43Z1e_3Wk'


const Operations = () => {
    // const videoSrc = Crude;
    // const poster = post
  return (
    <div className='flex lg:flex-row flex-col relative' id='operation'>
        <div className='flex flex-col basis-[30%] px-8 pt-10 lg:mt-8 pb-5 lg:pb-0 bg-[#000000]/70 h-[44.1rem]'>
            <span className='flex flex-col gap-2'>
                <span className='uppercase text-5xl font-medium text-white font-gilroyy'>operations</span>
                <hr className='border-2 border-[#FFD808] w-[15rem]'></hr>
            </span>
            <span className='text-2xl leading-6 font-light text-white/80 mt-5 mr-3 font-gilroy'>
                At the core of Maidstone Energy is a passion for execllent service delivery while conductuing business with high ethical standards.
            </span>
            <span className='text-2xl font-medium text-white mt-8 font-gilroyy'>Our focus areas include:</span>
            <div className='mt-6 space-y-6'>
                <span className='flex gap-6'>
                    <span className='bg-white p-[7px] border rounded-full border-[#000000]/70'><MdKeyboardDoubleArrowRight className='w-4 h-4 text-[#000000]/70'/></span>
                    <span className='text-2xl font-light text-white/80 font-gilroy'>Trading</span>
                </span>
                <span className='flex gap-6'>
                    <span className='bg-white p-[7px] border rounded-full border-[#000000]/70'><MdKeyboardDoubleArrowRight className='w-4 h-4 text-[#000000]/70'/></span>
                    <span className='text-2xl font-light text-white/80 font-gilroy'>Power</span>
                </span>
                <span className='flex gap-6 items-center'>
                    <span className='bg-white p-[7px] border rounded-full border-[#000000]/70'><MdKeyboardDoubleArrowRight className='w-4 h-4 text-[#000000]/70'/></span>
                    <span className='text-2xl font-light text-white/80 font-gilroy'>Infrastructure & Logistics</span>
                </span>
                <span className='flex gap-6'>
                    <span className='bg-white p-[7px] border rounded-full border-[#000000]/70'><MdKeyboardDoubleArrowRight className='w-4 h-4 text-[#000000]/70'/></span>
                    <span className='text-2xl font-light text-white/80 font-gilroy'>Retail</span>
                </span>
                
            </div>
        </div>
        <div className='flex flex-col basis-[70%] h-[46rem]'>
            <div className='lg:h-[38rem] h-[15rem] w-full'>
                {/* <VideoPlayer  src={videoSrc} poster={poster} width='920px' height='608px'/> */}
                <ReactPlayer url={url} controls={false} width='100%' height='100%' light={<img src={post} alt='Thumbnail' />} playing={true} loop={true}  className=''/>
            </div>
            <div className='bg-[#F05C40]/80 lg:-mt-8 -mt-3 lg:h-[10rem] h-[8rem] flex flex-col items-center justify-center gap-1 lg:gap-2'>
                <span className='lg:text-4xl text-xl font-normal text-white font-gilroy'>"...excellent service delivery and high </span>
                <span className='lg:text-4xl text-xl font-normal text-white font-gilroy'>ethical standards"</span>
            </div>
        </div>
    </div>
  )
}

export default Operations;