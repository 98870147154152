import React from 'react';
import About from './About';
import Business from './Business';
import Culture from './Culture';
import Header from './Header';
import Hero from './Hero';
import Operations from './Operations';
import Partners from './Partners';
import Contact from './Contact';

const Home = () => {
  return (
    <div className=' overflow-hidden'>
        <Header />
        <Hero />
        <About />
        <Culture />
        <Operations />
        <Business />
        <Partners />
        <Contact />
        
        
    </div>  
  )
}

export default Home;